// Import React
import React from 'react';

// Import Spectacle Core tags
import {
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  Notes,
  Appear
} from 'spectacle';

import { ScatterPlotCanvas,ScatterPlot } from '@nivo/scatterplot'
// Import theme
import createTheme from 'spectacle/lib/themes/default';

import ReactWordCloud from 'react-wordcloud'

import data from './data'

import saveAsSvg from 'save-svg-as-png'
import BarPlot from './barPlot'
import MyHeatMap from './heatmap'

import {interpolateRdYlGn} from 'd3-scale-chromatic'

const fiveDataRaw = require('./fiveSent.json')
const fiveData = fiveDataRaw.map(({name,count}) => {return {'name':name,'count':Math.round(count/(10**9))}} )
const oneDataRaw = require('./oneSent.json')
const oneData = oneDataRaw.map(({name,count}) => {return {'name':name,'count':Math.round(-count/(10**9))}} )

const vineDataRaw = require('./vineReviewers.json')
const vineData = vineDataRaw.map(
  (elem) => {
    let data = Object.assign({}, elem)
    data.x = Math.log(elem.x+1)
    data.y = Math.log(elem.y+1)
    return data;
  } )

console.log(vineData)
// Require CSS
require('normalize.css');

const avgSent = [
{"starRating":"1.0","value":-4.787773155119172E13+4.787773155119172E13},
{"starRating":"2.0","value":-4.074717197712159E13+4.787773155119172E13},
{"starRating":"3.0","value":-2.7738421735594832E13+4.787773155119172E13},
{"starRating":"4.0","value":-5.999382323664945E12+4.787773155119172E13},
{"starRating":"5.0","value":9.37941481141629E12 +4.787773155119172E13}
]


const gbtPredRaw = require('./gbtpreds.json');
const gbtPred = gbtPredRaw

const heatDataRaw = require('./avgVotesAvgRating.json')

const heatKeys = [
  "0.0",
"1.0",
"2.0",
"3.0",
"4.0",
"5.0",
"6.0",
"7.0",
"8.0"
]
const heatData = heatDataRaw.map( v => {
  let ret = Object.assign({},v);
  heatKeys.forEach(k => ret[k] = Math.round(Math.log(v[k])*10))
  return ret;
})

const listStyle = {
  listStyleImage: 'url("./starIcon.svg")'
}

const rfPredRaw = require('./rfRegPreds.json');
const rfPred = rfPredRaw
class Cloud extends React.Component {
  saveAsSvg() {
    saveAsSvg.svgAsDataUri(document.getElementsByTagName("svg")[1], {}, function(uri) {
      console.log(uri);
    })
  }
  render () {
    let {data} = this.props;
  return (
    <div style={{width:'100%',height:'50vh'}}>
      <ReactWordCloud
	words={data}
	wordCountKey='count'
  wordKey='name'
  onWordClick={this.saveAsSvg}
      />
      </div>
    
  );
  }
};

const Plot = ({data,label,colorFunc}) => {
  return <ScatterPlotCanvas
  data={data}
  margin={{
      "top": 20,
      "right": 40,
      "bottom": 0,
      "left": 40
  }}
  xScale={{
      "type": "linear",
      "min": "auto",
      "max": "auto"
  }}
  yScale={{
      "type": "linear",
      "min": "auto",
      "max": "auto"
  }}
  label
  height={250}
  width={400}
  axisTop={null}
  axisRight={null}
  axisBottom={null}
  axisLeft={null}
  colorBy={colorFunc}
  animate={true}
  motionStiffness={90}
  motionDamping={15}
  symbolSize={3}
  
/>
}

const theme = createTheme(
  {
    primary: 'white',
    secondary: '#1F2022',
    tertiary: '#03A9FC',
    quaternary: '#CECECE',
  },
  {
    primary: 'Montserrat',
    secondary: 'Helvetica',
  }
);
const colors = ["#F47560","#61CDBB"]
const colorTwoPred = ({pred}) => pred===0?"rgb(215, 48, 39)":"rgb(102, 189, 99)"
const colorTwoReal = ({positive}) => positive===0?"rgb(215, 48, 39)":"rgb(102, 189, 99)"

const colorByVine = ({isVine}) => isVine===0?"rgb(215, 48, 39)":"rgb(102, 189, 99)"
const colorByVinePred = ({pred}) => pred===0?"rgb(215, 48, 39)":"rgb(102, 189, 99)"

const colorAllPred = ({pred}) => interpolateRdYlGn((pred-1)/5)
const colorAllReal = ({rating}) => interpolateRdYlGn((Number(rating)-1)/5)

export default class Presentation extends React.Component {
  render() {
    return (
      <Deck
        transition={['fade']}
        transitionDuration={500}
        theme={theme}
      >
        <Slide transition={['fade']} bgColor="primary">
        <Notes>
        <h4>Slide notes</h4>
          <ol>
            <li>First note</li>
            <li>Second note</li>
          </ol>
        </Notes>
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            The Biggest Data:<br/>
            Amazon Reviews
             
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} bold>
            Alexander Hicks and Morgan King
          </Text>
        </Slide>
        <Slide transition={['fade']} bgColor="secondary" textColor="primary">
          <BlockQuote>
            <Quote>Ooh, I know the kid's word for this... YEET</Quote>
            <Appear><Cite>Dr. Fogarty</Cite></Appear>
          </BlockQuote>
        </Slide>
        <Slide transition={['fade']} bgColor="tertiary" textColor="secondary">
          <Heading size={3} textColor="primary" >
            The Dataset:
          </Heading>
          <Heading size={5} >
            120 Million Amazon Reviews
          </Heading>
          <List style={listStyle}>
            <Appear><ListItem>Electronics: ~2 million reviews and 1.7GB</ListItem></Appear>
            <Appear><ListItem>Includes Star Rating, Title, Body etc.</ListItem></Appear>
          </List>
        </Slide>
        <Slide transition={['fade']} bgColor="primary">
        <Notes>
        <h4>Slide notes</h4>
          <ol>
            <li>First note</li>
            <li>Second note</li>
          </ol>
        </Notes>
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            Part 1: Users
             
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} bold>
            They don't use much...
          </Text>
        </Slide>
        <Slide transition={['fade']} bgColor="secondary" textColor="primary">
          <Heading size={3} textColor="tertiary" >
            Questions
          </Heading>
          <Heading size={5} textColor="primary">
            Helpful Reviews
          </Heading>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Is there an easy way to classify if a user is a "helpful" reviewer?
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Are there any other interesting conclusions we can make from grouping reviews by user?
          </Text>
          </Appear>
        </Slide>
        <Slide>
          <Heading size={5} fit caps lineHeight={1} textColor="secondary">
            Most reviewers aren't helpful
             
          </Heading>
          <MyHeatMap data={heatData} />  
        </Slide>
        <Slide>
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            The Vine Program
             
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} bold>
            What makes helpful reviewers?
          </Text>
        </Slide>
        <Slide transition={['fade']} bgColor="tertiary" textColor="secondary">
          <Heading size={3} textColor="primary" >
            Our Approach
          </Heading>
          <Heading size={5} >
          Predicting Vine Reviewers
          </Heading>
          <List style={listStyle}>
            <Appear><ListItem>Measure average helpful votes, number of reviews, and total helpful votes</ListItem></Appear>
            <Appear><ListItem>Perform basic classification</ListItem></Appear>
          </List>
        </Slide>
        <Slide>
        <Heading size={5} fit caps lineHeight={1} textColor="secondary">
            Predicting Vine Reviewers
             
          </Heading>
          <Text margin="10px 10px 10px 10px" textColor="tertiary" size={6} bold>
            91.7% Accuracy
          </Text>
        <table style={{width:'700px'}}>
        <tr>
            <th>Real Value</th>
            <th>Predicitions</th> 
            
          </tr>
          <tr>
            <td><Plot data={[{"id":"data","data":vineData}]} label="pred" colorFunc={colorByVinePred}/></td>
            <td><Plot data={[{"id":"data","data":vineData}]} label="isVine" colorFunc={colorByVine}/></td>
          </tr>

        </table>
          
         
        </Slide>
        <Slide transition={['fade']} bgColor="primary">
        <Notes>
        <h4>Slide notes</h4>
          <ol>
            <li>First note</li>
            <li>Second note</li>
          </ol>
        </Notes>
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            Part 2: Reviews
             
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} bold>
            What do words mean?
          </Text>
        </Slide>

        <Slide transition={['fade']} bgColor="secondary" textColor="primary">
          <Heading size={3} textColor="tertiary" >
            Questions
          </Heading>
          <Heading size={5} textColor="primary">
            Sentiment Analysis
          </Heading>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Do the words in the review body indicate whether the review is positive or negitive?
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Can we predict the star rating of a review using only analysis of the words in the review body?
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Do pairs or larger groups of words make a bigger impact?
          </Text>
          </Appear>
        </Slide>

        <Slide transition={['fade']} bgColor="tertiary" textColor="secondary">
          <Heading size={3} textColor="primary" >
            Our Approach
          </Heading>
          <Heading size={5} >
            Sentiment Analysis
          </Heading>
          <List style={listStyle}>
            <Appear><ListItem>Count all word occurences in five star reviews and one star reviews</ListItem></Appear>
            <Appear><ListItem>Take weighted difference for each word between five and one star</ListItem></Appear>
          </List>
        </Slide>

        <Slide >
        <Heading size={3} textColor="secondary" transition={['fade']}>
            Most Common 5 Star Words
          </Heading>
          <img height={400} src={require('./download.svg')} />  
        </Slide>

        <Slide transition={['fade']} bgColor="secondary" textColor="tertiary">
        <Heading size={3} textColor="primary">
            Most Common 1 Star Words
          </Heading>
          <img height={400} src={require('./downloadBad.svg')} />  
          {/*<Cloud data={oneData}/>*/}
        </Slide>

        <Slide>
        <Heading size={5} >
          Relative Average "Sentiment"
          </Heading>
          <BarPlot data={avgSent} />  
        </Slide>
        
        
        
        <Slide transition={['fade']} bgColor="primary" textColor="tertiary" >
        <table style={{width:'700px'}}>
          <tr>
            <th></th>
            <th>Real Value</th>
            <th>Predicitions</th> 
            
          </tr>
          <tr>
            <td>Two class classification <br/><b style={{fontSize:18}}>84% Accuracy</b></td>
            <td><Plot data={[{"id":"data","data":gbtPred}]} label="positive" colorFunc={colorTwoReal}/></td>
            <td><Plot data={[{"id":"data","data":gbtPred}]} label="pred" colorFunc={colorTwoPred}/></td> 
          </tr>
          <tr>
            <td>Star Rating Regression<br/><b style={{fontSize:18}}>59.5% Accuracy</b></td>
            <td><Plot data={[{"id":"data","data":rfPred}]} label="positive" colorFunc={colorAllReal}/></td>
            <td><Plot data={[{"id":"data","data":rfPred}]} label="pred"colorFunc={colorAllPred}/></td> 
          </tr>
        </table>
          
        </Slide>

        <Slide transition={['fade']} bgColor="primary">
        <Notes>
        <h4>Slide notes</h4>
          <ol>
            <li>First note</li>
            <li>Second note</li>
          </ol>
        </Notes>
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            Part 3: Conclusions
             
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} bold>
            The suffering, oh the suffering
          </Text>
        </Slide>

        <Slide transition={['fade']} bgColor="secondary" textColor="primary">
          <Heading size={3} textColor="tertiary" >
            Failures
          </Heading>
          <Heading size={5} textColor="primary">
            Does not cluster
          </Heading>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Clustering users didn't produce any interesting groupings
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            The correlation matrix didn't produce any insights either
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="tertiary" size={1} textColor="primary">
            Didn't get to more complicated metrics for user classification
          </Text>
          </Appear>
        </Slide>

        <Slide transition={['fade']} bgColor="primary" textColor="secondary">
          <Heading size={3} textColor="tertiary" >
            Further Exploration
          </Heading>
          <Heading size={5} textColor="secondary">
            It's all fake news
          </Heading>
          <Appear><Text margin="20px" textColor="secondary" size={1}>
            Can we detect fake reviews using something similar to sentiment analysis?
          </Text>
          </Appear>
          <Appear><Text margin="20px" textColor="secondary" size={1}>
            Can we train a model to generate fake reviews?
          </Text>
          </Appear>
        </Slide>

        <Slide>
          <Heading size={1}  caps lineHeight={1} textColor="secondary">
            Questions?
             
          </Heading>
          <img height={400} src={require('./meme.jpg')} />  
        </Slide>
        {/*<Slide transition={['slide']} bgColor="tertiary">
          <Heading size={6} textColor="primary" caps>
            Typography
          </Heading>
          <Heading size={1} textColor="secondary">
            Heading 1
          </Heading>
          <Heading size={2} textColor="secondary">
            Heading 2
          </Heading>
          <Heading size={3} textColor="secondary">
            Heading 3
          </Heading>
          <Heading size={4} textColor="secondary">
            Heading 4
          </Heading>
          <Heading size={5} textColor="secondary">
            Heading 5
          </Heading>
          <Text size={6} textColor="secondary">
            Standard text
          </Text>
        </Slide>
        <Slide transition={['fade']} bgColor="primary" textColor="tertiary" >
          <Plot data={data}/>
        </Slide>
        
        <Slide transition={['fade']} bgColor="secondary" textColor="primary">
          <BlockQuote>
            <Quote>Example Quote</Quote>
            <Cite>Author</Cite>
          </BlockQuote>
    </Slide>*/}
      </Deck>
    );
  }
}
