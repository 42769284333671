import React from 'react';

import {Bar} from '@nivo/bar'

const BarPlot = ({data}) => {
    return <Bar
data={data}
keys={["value"]}
indexBy="starRating"
margin={{
    "top": 50,
    "right": 130,
    "bottom": 50,
    "left": 60
}}
padding={0.3}
width={700}
height={500}
colors={["#03A9FC"]}
colorBy="starRating"
borderColor="inherit:darker(1.6)"
axisBottom={{
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 0,
    "legend": "Star Rating",
    "legendPosition": "middle",
    "legendOffset": 32
}}
enableLabel={false}
axisLeft={null}
labelSkipWidth={12}
labelSkipHeight={12}
labelTextColor="inherit:darker(1.6)"
animate={true}
motionStiffness={90}
motionDamping={15}

/>
}

export default BarPlot