import { HeatMap } from '@nivo/heatmap'
import React from 'react';

const MyHeatMap = ({data}) => <HeatMap
        data={data}
        keys={[
            "0.0",
        "1.0",
        "2.0",
        "3.0",
        "4.0",
        "5.0",
        "6.0",
        "7.0",
        "8.0"
        ]}
        indexBy="starRating"
        margin={{
            "top": 100,
            "right": 60,
            "bottom": 60,
            "left": 60
        }}
        forceSquare={true}
        axisTop={{
            "orient": "top",
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": -90,
            "legend": "Log( Average helpful votes )",
            "legendPosition": "middle",
            "legendOffset": -40
        }}
        width={700}
        height={500}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
            "orient": "left",
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legend": "Average Review Rating",
            "legendPosition": "middle",
            "legendOffset": -40
        }}
        cellOpacity={1}
        cellBorderColor="inherit:darker(0.4)"
        labelTextColor="inherit:darker(1.8)"
        defs={[
            {
                "id": "lines",
                "type": "patternLines",
                "background": "inherit",
                "color": "rgba(0, 0, 0, 0.1)",
                "rotation": -45,
                "lineWidth": 4,
                "spacing": 7
            }
        ]}
        fill={[
            {
                "id": "lines"
            }
        ]}
        animate={true}
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        cellHoverOthersOpacity={0.25}
    />
export default MyHeatMap